<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  }
};
</script>
<style lang="less">
body {
  font-size: 16px;
  // background-color: #f8f8f8;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  img {
    width: 100%;
  }
}
#app {
  overflow: hidden;
}
</style>
